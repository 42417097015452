import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Container, Loader, Modal, Button } from "rsuite";
import Sidebar from './Sidebar'
import TopHeader from "./TopHeader";
import { getCurrentUser, isLogin, setLogin, setUser } from "../../redux/authSlice";
import { useChangeFirstTimeLoginMutation, useCurrentUserMutation } from "../../redux/api";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";

const SecurityLayout = ({ children }) => {
    return (
        <div className="show-fake-browser sidebar-page">
            <Container>
                <Sidebar />
                <Container>
                    <TopHeader />
                    {children}
                </Container>
            </Container>
        </div>
    )
}

const SecurityLayoutRoute = () => {
    let location = useLocation();
    const { t } = useTranslation();
    const [getUser, { isLoading }] = useCurrentUserMutation()
    const [changeFirstTimeLogin, { isLoading: isFirstTimeLoading }] = useChangeFirstTimeLoginMutation()
    const dispatch = useAppDispatch()

    const isAuth = useAppSelector(isLogin)
    const currentUser = useAppSelector(getCurrentUser)

    const [open, setOpen] = React.useState(currentUser?.firstTimeLogin);
    const handleClose = async () => {
        await changeFirstTimeLogin().then(res => {
            getUser().unwrap().then(response => {
                dispatch(setUser(response))
                setOpen(false);
            }).catch(err => {
                dispatch(setUser(null))
                dispatch(setLogin({ auth: false }))
            })
        }).catch(err => {

        })

    }

    useEffect(() => {
        getUser().unwrap().then(response => {
            dispatch(setUser(response))
        }).catch(err => {
            dispatch(setUser(null))
            dispatch(setLogin({ auth: false }))
        })
    }, [])


    if (isLoading)
        return <Loader size="lg" backdrop content={`${t("general.loading")}...`} vertical />
    else if (!(isAuth || currentUser)) {
        return <Navigate to="/user/login" state={{ from: location }} replace />;
    }
    else if (currentUser && open) {
        return <Modal backdrop="static" role="alertdialog" open={open} size="md">
            <Modal.Body>
                <b>Bu Eğitim Online  ve Uzaktan verilmektedir. Kesinlikle İptal ve İade İşlemi Gerçekleştirilememektedir.</b><br/>
                TANIMLAR VE KISALTMALAR<br/>
                İş bu sözleşmede yer alan;<br/>
                ÜNİVERSİTE : Burussels Capital Universty, Türkiye Enformasyon Bürosunu<br/>
                ÖĞRENCİ : Üniversiteye kaydının yapılmasını talep eden ve bu formu<br/>
                imzalayan kişi/kişileri, ifade etmektedir.<br/>
                3. TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ, ÖDEMELER<br/>
                3.1. Üniversite, Öğrencinin kayıt olduğu tarih itibari ile bölümüne ve fakültesine
                durumuna göre, öğrenciyi mezun etmekle mükelleftir. Bu süreler;<br/>
                3.1.1. Lisans programları için 4 yıldır. ( 8 dönemden oluşur.)<br/>
                3.1.2. Yüksek lisans programları için 2 yıldır. ( 4 dönem olup; İlk yıl ders, İkinci yıl
                tez aşamasından oluşur.)<br/>
                3.1.3. Doktora programları için 3 yıldır. (ilk yıl ders iki yıl tez aşamasından oluşur.)<br/>
                3.2. Yukarıda açık kimliği yazılı öğrenci, iş bu sözleşme kapsamında eğitimi için
                anlaşılmış olunan bedeli, iş bu sözleşme tarihinde ilgililerin belirlediği banka
                hesabına belirtilen vade tarihlerinde veya nakit olarak ödemekle mükelleftir.<br/>
                3.3. Borç taksitlerinin herhangi ikisi üst üste vadesinde ödenmemesi halinde hiçbir ihtara
                gerek olmaksızın taksitlendirilmiş bütün borçlar muaccel olacaktır. İki dönem üst üste
                taksit ödemesini gerçekleştirmeyen öğrencinin kaydı ASKIYA ALINIR, üçüncü
                ödemesini de gerçekleştirmeyen öğrencinin KAYDI SİLİNİR. Bu durumda öğrenci
                kurumdan hiçbir hak talep edemez.<br/>
                3.4. Herhangi bir sebepten dolayı oluşacak olan kayıt iptal işlemleri taahhüt edilen
                tutarların tamamının ödenmesi ile mümkün olacaktır. Ancak;<br/>
                3.4.1. Üniversite ve öğrenci açısından oluşabilecek mücbir sebep hallerinde taraflar
                birbirinden hiçbir hak ve talepte bulunamazlar. Ödeme talep edilmezi veya
                eğitim hizmeti sunulmasının beklenilmesi gibi. Bu haller örnekleyici olarak ;
                Haricilik (externality), kaçınılmazlık (unavoidability), öngörülmezlik
                (unforeseeability), İflas, kanuni grev, kısmi veya genel seferberlik halleridir.<br/>
                3.4.2. 3.4. maddesi hükmünün tek istisnası ; öğrencinin sağlık durumunun derslere
                devam edemeyecek durumda olduğunun heyet raporuyla belgelenmesi halinde
                (Eğitimin online eğitim olduğu hususu da gözetilerek bu ibarenin ilgili heyet
                raporunda açık birşekilde “online eğitim olarak devam ettiği eğitimini almaya
                engel derecede …….. hastalığı vardır” ibaresinin yer alması gerekmektedir.)
                eğitim ücretinin toplamının %50’sinden fazla olmamak şartıyla eğitim alınan
                sürenin toplam süre ile orantısı da dikkate alınmak şartıyla ücretin kısmi iadesi
                gerçekleşir.<br/>
                3.5. Öğrenci, Üniversite internet sitesinde yazılı bulunan, Üniversite yönetmeliklerine
                uyacağını peşinen kabul etmektedir. Üniversite yönetmeliklerine aykırı hareket eden
                öğrencinin kaydı silinir.<br/>
                3.6. Öğrenci üniversite tarafından kendisine bildirilen yazılı kaynakları ve kitapları
                kendisi temin eder, Üniversitenin alınacak olan kitaplar konusunda herhangi bir
                taahhüdü bulunmamaktadır.<br/>
                3.7. Üniversite, uzaktan eğitim veren bir kurum olup; T.C. kanun ve mevzuat hükümlerine
                göre ülkemizde yök denkliği yoktur. Aynı zamanda öğrenciye bu husus ile ilgili bir
                garanti verilmemektedir.<br/>
                3.8. Kayıt esnasında alınan kayıt ücreti ve vade tarihlerinde öğrenciden tahsil edilen
                ödemelerin hiçbir koşulda iadesi yapılmamaktadır. Kayıt bedeli ödenmeden kayıt
                işlemleri başlatılamaz. Kayıt ücreti eğitim ücretinden mahsup edilemez.<br/>
                3.9. Kayıt evrakının iş bu sözleşme tarihi itibari ile öğrenci veya temsilci tarafından 15
                gün içerisinde Üniversite adresine ulaştırılması gerekmektedir. Kayıt evrakını
                süresinde ulaştırmayan öğrencinin eğitim hesabı açılamaz, mezuniyet işlemleri
                sırasında dönem kaybı yaşanabilir ve diplomanın geç gelebilme durumu ortaya
                çıkabilir bu hususları öğrenci peşinen kabul eder. Öğrenci kayıt evrakında belirtilen
                bilgilerin ve ekinde sunduğu belgelerin doğruluğunu kabul ve bayan eder.<br/>
                4. GEÇERLİLİK SÜRESİ<br/>
                İş bu sözleşme tanzim ve imza tarihinden itibaren yürürlüğe girer ve bu tarihten
                başlayarak madde 3.1.1., 3.1.2., 3.1.3. fıkralarında belirtilen eğitim süreleri boyunca
                devam eder.<br/>
                5. DEKONT, MEKTUP VE İHTAR GÖNDERME<br/>
                Üniversite’nin öğrenciye dekont, mektup, ihtar gönderdiği hallerde öğrenci 7 gün içinde
                Üniversiteye itiraz etmediği takdirde bildirimin içeriğini kabul etmiş sayılır. Üniversite
                ihtarı iadeli taahhütlü mektupla veya noter aracılığı ile yapmakta serbesttir. Öğrenci
                üniversitenin kendisine göndereceği dekont, mektup ve benzeri yazışmaların haberleşme
                ücreti de dahil olmak üzere her türlü masraf ve benzeri giderleri nakden ve defaten
                ödemeyi kabul beyan ve taahhüt eder.<br/>
                6. ÖDEME VE MUACCELİYET<br/>
                Ödemeler, sözleşmenin eki olan ödeme planında gösterilen şekilde geri ödenecektir.
                Süresinde ödenmeyen miktar yasal faiz uygulanmak suretiyle tahsil edilecektir. Kısmi
                olarak yatırılan miktarlar eski tarihli olan borçtan başlanarak düşülecektir.<br/>
                7. GİZLİLİK<br/>
                Üniversite, bu sözleşme konusu işlemlerin yapılması ile ilgili tüm bilgileri, özellikle
                şahsi bilgileri, telefon numaralarını, kimlik ve adres bilgilerini sadece bu sözleşme
                amaçları için kullanmayı, iş bu sözleşmenin imzalanması ve uygulanması neticesinde elde
                ettiği/edeceği bilgileri kanunen yetkili kılınan merciler dışında üçüncü şahıslara
                açıklamayacağını, bilgilerin güvenlik standartlarına uygun olarak alınması ve
                saklanmasını temin etmeyi, aksine davranılmasının sonuçlarından tamamıyla kendisinin
                sorumlu olduğunu kabul ve taahhüt eder.
                İş bu sözleşme sona erse dahi iş bu gizlilik yükümlülükleri geçerli olmaya devam
                edecektir.<br/>
                8. DELİL SÖZLEŞMESİ<br/>
                Öğrenci iş bu sözleşmeden doğabilecek ihtilaflarda ÜNİVERSİTE’nin usulüne uygun
                olarak tutulmuş defter ve kayıtları ile bilgisayar kayıtlarının HUMK 287. Maddesi
                anlamında muteber, bağlayıcı ve kesin delil teşkil edeceğini, ÜNİVERSİTE’nin yemin
                teklifinden ber’i kılındığını ve iş bu maddenin kesin delil sözleşmesi niteliğinde olduğunu
                kabul, beyan, taahhüt eder.<br/>
                9. HAKLARIN KULLANIMI<br/>
                İş bu sözleşmenin ÜNİVERSİTE’ye tanıdığı hakların kısmen veya tamamen
                kullanılmaması, üniversitenin bu haklardan vazgeçtiği anlamına gelmeyecektir. iş bu
                sözleşme ile üniversiteye tanınmış haklar herhangi bir süre sınırlamasına tabi olmaksızın
                ÜNİVERSİTE tarafından kullanılabilecektir.<br/>
                10. TEBLİGAT ADRESİ<br/>
                İş bu sözleşmedeki hususların yerine getirilmesi için, yapılacak her türlü yazışma ve
                tebligatlar, bu sözleşmede belirtilen adreslere gönderilecek ve öğrenci adres değişikliğini
                ÜNİVERSİTE’ye bildirmediği takdirde bu adreslere yapılacak tebligatlar geçerli olacaktır.<br/>
                11. YETKİLİ MAHKEME<br/>
                İş bu sözleşmeden doğacak uyuşmazlıkların çözümünde Türk Hukuku geçerli olup,
                İstanbul Mahkeme Ve İcra Dairelerinin yetkili olduğu taraflarca kabul edilmiştir.<br/>
                12. YÜRÜRLÜK<br/>
                12 maddeden oluşan ve ekten (taksitli borç senedi) iş bu sözleşme tarihinde 1
                (bir) nüsha olarak düzenlenmiş ve taraflarca kabul ve imzalanarak yürürlüğe girmiştir.
                Öğrencinin ÜNİVERSİTE’ye teslim ettiği evrak kapak sayfası hariç, toplam 4 sayfadan
                ibarettir. İş bu orijinal nüsha ÜNİVERSİTE’de kalacaktır.<br/>
            </Modal.Body>
            <Modal.Footer>
                <Button loading={isFirstTimeLoading} onClick={handleClose} appearance="primary">
                    {t("general.accept")}
                </Button>
            </Modal.Footer>
        </Modal>
    }
    else {
        return <SecurityLayout>
            <Outlet />
        </SecurityLayout>
    }
};

export default SecurityLayoutRoute